<template>
  <v-container>
    <base-card>
      <div slot="heading" class="title font-weight-light">SignUp Requests</div>

      <v-tabs right>
        <v-tab @click="accepted = false">Waiting</v-tab>
        <v-tab @click="accepted = true">Accepted</v-tab>
      </v-tabs>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="5%">ID</th>
              <th class="text-left" width="30%">
                {{ $t('signup.email') }}
              </th>
              <th class="text-left" width="30%">
                {{ $t('signup.organization_name') }}
              </th>
              <th class="text-left" width="15%">
                {{ $t('signup.created_at') }}
              </th>
              <th class="text-right" width="15%">
                {{ $t('common.actions') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(r, index) in signupRequests"
              :key="index"
              class="text-green"
              :class="{ 'text-light-green': r.accent }"
            >
              <td>{{ r.id }}</td>
              <td>{{ r.email }}</td>
              <td>{{ r.organization_name }}</td>
              <td>{{ r.created_at | dateFormat('YYYY / MM / DD HH:mm') }}</td>
              <td class="text-right">
                <v-btn outlined @click="onConfirmAccept(r)" v-if="!r.accepted">
                  {{ $t('signup.accept') }}
                </v-btn>
                <span v-else class="green--text font-weight-bold">
                  <v-icon class="green--text mr-2"> check </v-icon>
                  {{ $t('signup.accepted') }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </base-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseCard from '../components/common/BaseCard.vue'

export default {
  components: {
    BaseCard,
  },

  data() {
    return {
      accepted: false,
      requestId: null,
    }
  },

  mounted() {
    this.fetchSignUpRequests(this.accepted)
  },
  watch: {
    accepted() {
      this.fetchSignUpRequests(this.accepted)
    },
  },
  computed: {
    ...mapState({
      signupRequests: (state) => state.signupRequest.requests,
    }),
  },

  methods: {
    ...mapActions(['fetchSignUpRequests', 'acceptSignUpRequest']),

    async onConfirmAccept(r) {
      if (
        await this.$root.$confirm(
          this.$t('signup.accept'),
          this.$t('signup.confirm', { org_name: r.organization_name })
        )
      ) {
        await this.acceptSignUpRequest({ requestId: r.id })
        this.$snotify.success(this.$t('signup.accept_successfully'))
        this.fetchSignUpRequests(this.accepted)
      }
    },
  },
}
</script>
